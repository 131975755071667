export const Peculiarities = (props) => {
  return (
    <div id='peculiarities'>
      <div className='container'>
        <div className='section-title text-center'>
          <h2>Преимущества</h2>
        </div>
        <div className='section-title about-text text-center'>
          <h4>Использование собственных, специально разработанных протоколов и алгоритмов:</h4>
        </div>
        <div className='about-text'>
          <div className='list-style'>
            <ul>
              {props.data
                ? props.data.text.map((d, i) => (
                  <li key={`${d}-${i}`}>{d}</li>
                ))
                : 'loading'}
            </ul>
          </div>
        </div>
      </div>
    </div>
  )
}
