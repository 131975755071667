export const Features = (props) => {
  return (
    <div id='features'>
      <div className='container'>
        <div className='section-title text-center'>
          <h2>Возможности</h2>
        </div>

        <div className='about-text'>
          <div className='list-style'>
            <ul>
              {props.data
                ? props.data.text.map((d, i) => (
                  <li key={`${d}-${i}`}>{d}</li>
                ))
                : 'loading'}
            </ul>
          </div>
        </div>
      </div>
    </div>
  )
}
