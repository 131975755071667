export const About = (props) => {
  return (
    <div id='about'>
      <div className='container'>
      <div className='section-title text-center'>
        <h2>Описание</h2>
        </div>

          <div className='about-text'>
            <p>{props.data ? props.data.paragraph1 : 'loading...'}</p>
            <p>{props.data ? props.data.paragraph2 : 'loading...'}</p>
            <p>{props.data ? props.data.paragraph3 : 'loading...'}</p>
            <p>{props.data ? props.data.paragraph4 : 'loading...'}</p>
            <p>{props.data ? props.data.paragraph5 : 'loading...'}</p>
          </div>
      </div>
    </div>
  )
}
//<p className='text-center'><video src="video/otan-mektep.mp4" width="800" controls="controls" autoplay="true" loop muted /></p>
//<p className='text-center'><video src="video/otan-mektep.mp4" width="600" height="300" controls="controls" autoplay="true" loop muted /></p>
