export const Services = (props) => {
  return (
    <div id='services' >
      <div className='container'>
        <div className='section-title'>
          <h2 className='text-center'>Наши услуги</h2>
        </div>
        <div className='section-title text-center'>
            <h3 >Мы предоставляем полный спектр услуг от постановки задачи до реализации "под ключ"</h3>
        </div>
        <div className='row'>
          {props.data
            ? props.data.row1.map((d, i) => (
                <div key={`${d.name}-${i}`} className='col-md-4'>
                  <div className='service-desc'>
                    <h3>{`${d.num}.  ${d.name}`}</h3>
                    <p>{d.text}</p>
                  </div>
                </div>
              ))
            : 'loading'}
        </div>
        <div className='row'>
          {props.data
            ? props.data.row2.map((d, i) => (
                <div key={`${d.name}-${i}`} className='col-md-4'>
                  {' '}
                  <div className='service-desc'>
                  <h3>{`${d.num}.  ${d.name}`}</h3>
                    <p>{d.text}</p>
                  </div>
                </div>
              ))
            : 'loading'}
        </div>
        <div className='row'>
          {props.data
            ? props.data.row3.map((d, i) => (
                <div key={`${d.name}-${i}`} className='col-md-4'>
                  {' '}
                  <div className='service-desc'>
                  <h3>{`${d.num}.  ${d.name}`}</h3>
                    <p>{d.text}</p>
                  </div>
                </div>
              ))
            : 'loading'}
        </div>
      </div>
    </div>
  )
}
