export const Patents = (props) => {
  return (
    <div id='patents' className='text-center'>
      <div className='container'>
        <div className='col-md-8 col-md-offset-2 section-title'>
          <h2>Наши сертификаты и патенты</h2>
          <p>
          </p>
        </div>
        <div class="container">
          <div class="row">
          {props.data
                    ? props.data.map(function (d, i){
                      console.log(d,i)
                      if (i < 3) {
                        return (
                          <div key={`${d.name}-${i}`} className='col-sm-4 section-title'>
                            <div className='thumbnail'>
                              {' '}
                              <img src={d.img} alt='...' className='team-img' />
                              <div className='caption'>
                                <h4>{d.name}</h4>
                                <p>{d.job}<br />{d.owner}</p>
                              </div>
                            </div>
                          </div>
                        )
                      }
                    })
                    : 'loading'}
            
          </div>
        </div>
        <p></p>
        <div class="container">
          <div class="row">
          {props.data
                    ? props.data.map(function (d, i){
                      console.log(d,i)
                      if (i >= 3 && i <=5) {
                        return (
                          <div key={`${d.name}-${i}`} className='col-sm-4 section-title'>
                            <div className='thumbnail'>
                              {' '}
                              <img src={d.img} alt='...' className='team-img' />
                              <div className='caption'>
                                <h4>{d.name}</h4>
                                <p>{d.job}<br />{d.owner}</p>
                              </div>
                            </div>
                          </div>
                        )
                      }
                    })
                    : 'loading'}
            
          </div>
        </div>
        
      </div>
    </div>
  )
}
